<ng-container *ngIf="alwaysShownItems$ | async as alwaysShownItems">
  <ng-container *ngIf="hideableItems$ | async as hideableItems">
    <div class="border rounded">
      <ng-container *ngIf="hideableItems.length !== 0">
        <div data-cy="always-shown-portion">
          <ng-container *ngTemplateOutlet="listItems; context: { $implicit: alwaysShownItems }"></ng-container>
        </div>

        <div class="border-top" [(ngbCollapse)]="isCollapsed" data-cy="hideable-portion">
          <ng-container *ngTemplateOutlet="listItems; context: { $implicit: hideableItems }"></ng-container>
        </div>

        <div class="collapse-toggle py-3 border-top" (click)="toggleCollapse()" data-cy="collapsible-toggle">
          <div class="d-flex justify-content-between align-items-center">
            <span class="text-primary">Show {{ isCollapsed ? hideableItems.length + ' more' : 'less' }}</span>
            <omgui-collapse-state-icon [isOpen]="!isCollapsed"></omgui-collapse-state-icon>
          </div>
        </div>
      </ng-container>

      <div *ngIf="hideableItems.length === 0">
        <ng-container *ngTemplateOutlet="listItems; context: { $implicit: alwaysShownItems }"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #listItems let-items>
  <omgui-list-card [borderless]="true">
    <omgui-list-card-item *ngFor="let item of items" [data]="item.data">
      <ng-template omgui-list-card-item-icon>
        <ng-container *ngTemplateOutlet="item.icon.templateRef"></ng-container>
      </ng-template>
    </omgui-list-card-item>
  </omgui-list-card>
</ng-template>
