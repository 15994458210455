<ng-template #cardContent>
  <div class="position-relative h-100">
    <div
      class="card omgui-card d-flex p-3 p-sm-4 h-100"
      [ngClass]="{
        'flex-row flex-sm-column flex-md-row align-items-center align-items-sm-start align-items-md-center justify-content-start':
          fullWidthAlignment === 'horizontal',
        'flex-column align-items-start justify-content-start': fullWidthAlignment === 'vertical'
      }"
    >
      <div
        *ngIf="icon"
        class="icon"
        [ngClass]="{
          'mr-3 mr-md-4 mb-sm-2 mb-md-0': fullWidthAlignment === 'horizontal',
          'mb-2': fullWidthAlignment === 'vertical'
        }"
      >
        <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
      </div>

      <div>
        <h4 class="h5 m-0">{{ label }}</h4>
        <div class="sublabel text-emphasis-medium" data-cy="omgui-standard-card-sublabel-content">
          <!-- Allow only sublabel or secondaryLabel but not both -->
          <ng-container *ngIf="sublabel; else secondaryLabelContent">{{ sublabel }}</ng-container>
          <ng-template #secondaryLabelContent>
            <ng-container *ngTemplateOutlet="secondaryLabel?.templateRef"></ng-container>
          </ng-template>
        </div>
      </div>
    </div>

    <div *ngIf="footer" class="position-absolute w-100 text-center mx-auto my-2 d-none d-sm-block text-emphasis-medium">
      <ng-container *ngTemplateOutlet="footer.templateRef"></ng-container>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="link; then linkView; else defaultView"></ng-container>

<ng-template #defaultView>
  <div role="button" (click)="onClick($event)" class="omgui-card-link-wrapper h-100">
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </div>
</ng-template>

<ng-template #linkView>
  <a
    class="text-decoration-none omgui-card-link-wrapper h-100"
    [routerLink]="link"
    [omTrackLink]="trackLink"
    [queryParams]="queryParams"
  >
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-template>
