import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import Rollbar, { Configuration } from 'rollbar';

import { environment } from '../../environments/environment';

// This needs to be in this file to have typescript
// accept global.CODE_VERSION getting injected by
// webpack.
declare let global: { CODE_VERSION: string };

const rollbarConfig: Configuration = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  payload: {
    environment: environment.rollbarEnvironment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: global.CODE_VERSION,
      },
    },
    server: {
      root: 'webpack:///./', // https://docs.rollbar.com/docs/source-control#serverroot
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    const error = err.originalError || err;
    if (environment.rollbarAccessToken) {
      this.rollbar.error(error);
    } else {
      console.error(error);
    }
  }
}
