import { CommonModule } from '@angular/common';
import { Component, ContentChild, Directive, EventEmitter, Input, NgModule, Output, TemplateRef } from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';

import { TrackLinkModule } from '@app/utils/track-link.directive';

@Directive({
  selector: '[omgui-standard-card-icon]',
})
export class OmguiStandardCardIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[omgui-standard-card-footer]',
})
export class OmguiStandardCardFooterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/** For adding rich content to the bottom of the content area */
@Directive({
  selector: '[omgui-standard-card-secondary-label]',
})
export class OmguiStandardCardSecondaryLabelDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omgui-standard-card',
  templateUrl: './omgui-standard-card.component.html',
  styleUrls: ['./omgui-standard-card.component.scss'],
})
export class OmguiStandardCardComponent {
  @ContentChild(OmguiStandardCardIconDirective) icon: OmguiStandardCardIconDirective;

  /**
   * Load rich HTML content into the content area by passing a ng-template with [omgui-standard-card-secondary-label]
   * For simple text content, use #sublabel
   */
  @ContentChild(OmguiStandardCardSecondaryLabelDirective) secondaryLabel: OmguiStandardCardSecondaryLabelDirective;
  @ContentChild(OmguiStandardCardFooterDirective) footer: OmguiStandardCardFooterDirective;

  /** The title or main text */
  @Input() label: string;

  /** Subtitle or a short description */
  @Input() sublabel?: string;

  /** Navigation link */
  @Input() link: string;

  /** Optional function that is executed before the linked resource is navigated to. Meant to be assigned an analytics tracking function */
  @Input() trackLink?: () => Observable<unknown> | undefined;

  /** Query params will be passed to the routerLink */
  @Input() queryParams: Params;

  /** Direction to align card icon and content */
  @Input() fullWidthAlignment: 'horizontal' | 'vertical' = 'horizontal';

  /** Emits an event on click */
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  onClick(event: Event): void {
    event.preventDefault();
    this.clicked.emit();
  }
}

@NgModule({
  declarations: [
    OmguiStandardCardComponent,
    OmguiStandardCardIconDirective,
    OmguiStandardCardFooterDirective,
    OmguiStandardCardSecondaryLabelDirective,
  ],
  imports: [CommonModule, RouterModule, TrackLinkModule],
  exports: [
    OmguiStandardCardComponent,
    OmguiStandardCardIconDirective,
    OmguiStandardCardFooterDirective,
    OmguiStandardCardSecondaryLabelDirective,
  ],
})
export class OmguiStandardCardModule {}
