import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';

@Component({
  selector: 'om-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class Login implements OnInit {
  signInText: string;

  constructor(
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.title.setTitle('Login');
    this.signInText = 'Please log in to your One Medical account';

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.analyticsService.trackLoginPageView(params);
    });
  }
}
