/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressKind {
  billing = 'billing',
  home = 'home',
  other = 'other',
  work = 'work',
}

export enum ContentBlockMetadataStyles {
  Dark = 'Dark',
  ErrorLight = 'ErrorLight',
  InfoLight = 'InfoLight',
  Light = 'Light',
  SuccessLight = 'SuccessLight',
  WarningLight = 'WarningLight',
}

export enum EmailVerificationStatus {
  locked_out = 'locked_out',
  unverified = 'unverified',
  verified = 'verified',
}

export enum OnboardingTaskName {
  health_information_exchange_page = 'health_information_exchange_page',
  welcome_modal = 'welcome_modal',
}

export enum OnboardingTaskStatus {
  complete = 'complete',
  incomplete = 'incomplete',
}

export enum PatientPhoneNumberKind {
  home = 'home',
  mobile = 'mobile',
  other = 'other',
  work = 'work',
}

export enum PhoneNumberKind {
  back_office = 'back_office',
  fax = 'fax',
  home = 'home',
  mobile = 'mobile',
  other = 'other',
  pager = 'pager',
  patient_assistance = 'patient_assistance',
  work = 'work',
}

export enum SendOrReceive {
  RECEIVE = 'RECEIVE',
  SEND = 'SEND',
}

export enum SurveyAnswerCheckboxCustomBehavior {
  select_all = 'select_all',
  select_all_excluded = 'select_all_excluded',
  select_none = 'select_none',
}

export enum SurveyAnswerDatePickerCustomBehavior {
  future = 'future',
  month_year_only = 'month_year_only',
  past = 'past',
}

export enum SurveyAnswerKind {
  binary_radio = 'binary_radio',
  boolean = 'boolean',
  checkbox = 'checkbox',
  date_picker = 'date_picker',
  date_range = 'date_range',
  date_string = 'date_string',
  free_text = 'free_text',
  fsa_hsa_grid_input = 'fsa_hsa_grid_input',
  horizontal_radio = 'horizontal_radio',
  multi_select = 'multi_select',
  radio = 'radio',
  scale = 'scale',
  select = 'select',
}

export interface AddressAttributes {
  id?: string | null;
  kind?: AddressKind | null;
  address1?: string | null;
  address2?: string | null;
  crossStreet?: string | null;
  cross_street?: string | null;
  city?: string | null;
  stateCode?: string | null;
  state_code?: string | null;
  zip?: string | null;
  _destroy?: (boolean | null)[] | null;
}

export interface BookAppointmentAttributes {
  appointmentTypeId: string;
  inventoryId: string;
  sendSms?: boolean | null;
  smsNumber?: string | null;
  reason?: string | null;
}

/**
 * Autogenerated input type of BookAppointment
 */
export interface BookAppointmentInput {
  appointmentAttributes: BookAppointmentAttributes;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CancelAppointment
 */
export interface CancelAppointmentInput {
  id: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CancelPrescriptionRenewalRequests
 */
export interface CancelPrescriptionRenewalRequestsInput {
  patientMedicationIds: string[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of ChangePharmacyForPrescriptionRenewalRequests
 */
export interface ChangePharmacyForPrescriptionRenewalRequestsInput {
  patientPharmacyId: string;
  patientMedicationIds: string[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreateCustomPharmacy
 */
export interface CreateCustomPharmacyInput {
  isMailOrder?: boolean | null;
  name: string;
  phoneNumbersAttributes: PhoneNumberAttributes;
  addressAttributes: AddressAttributes;
  customerCode?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of CreatePatientPharmacy
 */
export interface CreatePatientPharmacyInput {
  pharmacyId: string;
  pharmacy_id?: string | null;
  customerCode?: string | null;
  clientMutationId?: string | null;
}

export interface CreatePrescriptionRenewalRequestAttributes {
  patientMedicationId: string;
  comment?: string | null;
}

/**
 * Autogenerated input type of CreatePrescriptionRenewalRequests
 */
export interface CreatePrescriptionRenewalRequestsInput {
  patientPharmacyId: string;
  createPrescriptionRenewalRequestAttributes: CreatePrescriptionRenewalRequestAttributes[];
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of MarkAllUserNotificationsAsRead
 */
export interface MarkAllUserNotificationsAsReadInput {
  postId?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of MarkAsLateToAppointment
 */
export interface MarkAsLateToAppointmentInput {
  id: string;
  clientMutationId?: string | null;
}

export interface PatientAddressInputObject {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  id?: string | null;
  isPreferred?: boolean | null;
  state?: string | null;
  zip?: string | null;
  destroy?: boolean | null;
}

export interface PatientPhoneNumberInputObject {
  id?: string | null;
  ext?: string | null;
  isPreferred?: boolean | null;
  kind?: PatientPhoneNumberKind | null;
  number?: string | null;
  _destroy?: boolean | null;
}

export interface PatientSurveyAnswer {
  answerId?: string | null;
  answerText?: string | null;
  kind?: SurveyAnswerKind | null;
  surveyQuestionId?: string | null;
}

export interface PhoneNumberAttributes {
  id?: string | null;
  kind?: PhoneNumberKind | null;
  number?: string | null;
  ext?: string | null;
  _destroy?: (boolean | null)[] | null;
}

export interface RescheduleAppointmentAttributes {
  appointmentTypeId: string;
  sendSms?: boolean | null;
  smsNumber?: string | null;
  inventoryId: string;
}

/**
 * Autogenerated input type of RescheduleAppointment
 */
export interface RescheduleAppointmentInput {
  fromAppointmentId: string;
  newAppointmentAttributes: RescheduleAppointmentAttributes;
  appointmentCancellationReasonId?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of SendAppLink
 */
export interface SendAppLinkInput {
  phoneNumber: string;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
