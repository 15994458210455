export const FeatureFlags = {
  APPOINTMENT_SEARCH_NEXT_REMOTE_VISIT_MODULE: 'next-remote-visit-module',
  COLOR_SYSTEM_REDESIGN: 'color-system-redesign',
  CONSUMER_PEDIATRIC_REGISTRATION_FREE_TRIAL: 'consumer-pediatric-registration-free-trial',
  CONSUMER_REGISTRATION_APP_SYNC_COMMUNICATION: 'consumer-registration-app-sync-communication',
  CONSUMER_REGISTRATION_APP_SYNC_GIFTING_FLOW: 'consumer-registration-app-sync-gifting-flow',
  CONSUMER_REGISTRATION_APPLE_PAY_WEB: 'consumer-registration-apple-pay-web',
  CONSUMER_REGISTRATION_DPH_BANNER_MESSAGE: 'consumer-registration-dph-banner-message',
  CONSUMER_REGISTRATION_EXPERIMENTATION_TEST: 'consumer-registration-experimentation-test',
  CONSUMER_REGISTRATION_INSTALLMENT_PAYMENTS: 'consumer-registration-installment-payments',
  CONSUMER_MARKET_FLEXIBLE_PRICING: 'consumer-market-flexible-pricing',
  CONSUMER_REGISTRATION_PASSWORDLESS_FIRST_PAGE: 'consumer-registration-passwordless-first-page',
  CONSUMER_REGISTRATION_PROMOTION: 'consumer-registration-promotion',
  CONSUMER_REGISTRATION_STEP_BASED_BANNER: 'consumer-registration-step-based-banner',
  CONSUMER_REGISTRATION_STRIPE_PAYMENT_INTENTS: 'consumer-registration-stripe-payment-intents',
  CONSUMER_REGISTRATION_VALUE_PROPS_INTERSTITIAL: 'consumer-registration-value-props-interstitial',
  FAMILY_ACCOUNT_MANAGEMENT_TAB: 'family-account-management-tab',
  NEW_FORGOT_PASSWORD_PAGE: 'new-forgot-password-page',
  HOME_SCREEN_REDESIGN: 'home-screen-redesign',
  MEDICAL_RECORDS_DOWNLOAD: 'patient-medical-records-download-request',
  MEMBERSHIP_NEW_STRIPE_WORKFLOW_CONSUMER_REGISTRATION: 'membership-new-stripe-workflow-consumer-registration',
  MEMBERSHIP_NEW_STRIPE_WORKFLOW_GIFT_MEMBERSHIP: 'membership-new-stripe-workflow-gift-membership',
  MESSAGING_MARK_AS_READ: 'messaging-mark-as-read',
  POST_REGISTRATION_BOOKING: 'post-registration-booking',
  BHX_MEMBER_PROGRAM_EXPERIENCE: 'member-program-experience',
  BHX_PCP_MENTAL_HEALTH_VISIT: 'pcp-mental-health-visit-experiment',
  BHX_PCP_SELECTION_MENTAL_HEALTH_VISIT_EXPERIMENT: 'pcp-selection-mental-health-visit-experiment',
  PROACTIVE_CARE_ENABLE_CONCIERGE: 'proactive-care-enable-concierge',
  PROACTIVE_CARE_ENABLE_CONSUMER_MULTIPLE_SLOTS_BOOKING: 'proactive-care-enable-multiple-slots-booking',
  PROACTIVE_CARE_ENABLE_PCP_SELECTION_ONBOARDING_CAROUSEL_CARD:
    'proactive-care-enable-pcp-selection-onboarding-carousel-card',
  REFERRAL_PDF: 'referral-pdf',
  TYPE_SYSTEM_REDESIGN: 'type-system-redesign',
  PATIENT_UI_NAVBAR_REFACTOR: 'patient-ui-navbar-refactor',
};

export enum HomeRedesignVariation {
  OFF = 'Off',
}
