import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';
import moment from 'moment';

import { State } from '@app/app.reducer';
import { ANALYTICS_TIMESTAMP_FORMAT } from '@app/core/analytics.service';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MixpanelService } from '@app/core/mixpanel.service';
import { MENTAL_HEALTH_VISIT_APPT_TYPE_ID } from '@app/home/cards/mental-health-pcp-visit-card/mental-health-pcp-visit-card.component';
import { AppointmentBookingSource } from '@app/shared/appointment-booking-source';

import { AppointmentAnalyticsBaseService } from '../core/appointment-analytics-base.service';
import { EVENT_BOOK_VISIT_CLICKED, MP_EVENT_PAGE_VIEWED } from '../core/mixpanel.constants';
import { SurveyQuestion } from '../survey/survey-models';
import { ServiceArea } from './../shared/service-area';
import { AppointmentBookingState } from './appointment-booking-state-service/appointment-booking-state';
import { AppointmentSearchState } from './appointment-search-service/appointment-search-state';
import { AppointmentType } from './appointment-type';
import { AppointmentInventory } from './provider-inventories';

export enum TrackableFilterType {
  DATE = 'date',
  LOCATION = 'location',
}

export enum AppointmentAnalyticsProperty {
  AppointmentConfirmationPageModule = 'Appointment Confirmation Page',
  BehavioralHealthProgramIneligiblePageModule = 'Behavioral Health Program Ineligible Page',
  BookingFlow = 'Appointment Booking',
  BookingPageModule = 'Appointment Booking Page',
  InventoryPageModule = 'Appointment Inventory Page',
  ReasonForReschedulePageModule = 'Reason for Reschedule Page',
  RescheduleFlow = 'Appointment Reschedule',
  OMNowNavigationFlow = 'OM Now Navigation',
  VirtualOnlyLandingPageModule = 'Virtual Only Landing Page',
  GetVirtualCareCardSubmodule = 'Get Virtual Care Card',
  MessageProviderCardSubmodule = 'Message Provider Card',
  MindsetEducationPageModule = 'Enterprise Mindset Education Page',
  NextAvailableRemoteVisitSubmodule = 'Next Available Remote Visit Widget',
  GetCareCardSubmodule = 'Get Care Card',
  SelfPayConfirmationModule = 'Self Pay Confirmation Modal',
  ShiftEducationPageModule = 'Shift Education Page',
  MemberRequestFlow = 'Member Request',
  AppointmentsPageModule = 'Appointment Overview Page',
  SelectedAppointmentBookingModal = 'Selected Appointment Booking Modal',
  DeepLinkModule = 'Deep Link Page Source',
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentAnalyticsService extends AppointmentAnalyticsBaseService {
  protected bookingState: AppointmentBookingState;
  protected searchState: AppointmentSearchState;
  private bookingStartedParams?: { module: AppointmentAnalyticsProperty; flow: AppointmentAnalyticsProperty };

  constructor(
    mixpanel: MixpanelService,
    store: Store<State>,
    launchDarkly: LaunchDarklyService,
    private route: ActivatedRoute,
  ) {
    super(mixpanel, store, launchDarkly);
  }

  bookingStarted() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Started', this.bookingStartedParams);
  }

  reasonSubmitted() {
    return this.trackWithBookingAndSearchStates('Appointment Reason Submitted');
  }

  bookingPageViewed(pageName: string, properties: object = {}) {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: pageName,
      source: this.route.snapshot.queryParams.source,
      ...properties,
    });
  }

  searched() {
    this.trackLaunchDarklyDeepLinkInventoryPageViewed(); // BHX team tracking experiment
    return this.trackWithBookingAndSearchStates('Appointment Inventory Searched', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_type: this.searchState.appointmentType.displayName,
    });
  }

  searchFailed() {
    return this.trackWithBookingAndSearchStates('Appointment Inventory Search Failed');
  }

  appointmentTypeSelected(appointmentType: AppointmentType) {
    return this.trackWithBookingAndSearchStates('Appointment Type Selected', {
      appointment_type: appointmentType.displayName,
      flow: 'Appointment Booking',
    });
  }

  inventorySelected(inventory: AppointmentInventory, props?: any) {
    const properties: any = {
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    };
    if (!!inventory) {
      properties.appointment_inventory_id = inventory.id;
    }
    if (props && props.isRemoteRecommendation) {
      properties.submodule = AppointmentAnalyticsProperty.NextAvailableRemoteVisitSubmodule;
    }
    if (props?.appointmentType) {
      properties.appointment_type = props.appointmentType;
    }
    return this.trackWithBookingAndSearchStates('Appointment Selected', properties);
  }

  seeMoreAppointmentsClicked(inventoryCount: number, props: any) {
    return;
  }

  filterApplied(filterType: string, filterDetails: object) {
    const properties = {
      filter_type: filterType,
      ...filterDetails,
    };
    return this.trackWithBookingAndSearchStates('Filter Applied', properties);
  }

  paginateNext() {
    return this.trackWithBookingAndSearchStates('Next Button Clicked');
  }

  paginatePrevious() {
    return this.trackWithBookingAndSearchStates('Previous Button Clicked');
  }

  bookAppointmentClicked(inventory: AppointmentInventory) {
    const trackProps = {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_inventory_id: inventory.id,
      selected_date: inventory.start_time.format(ANALYTICS_TIMESTAMP_FORMAT),
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      submodule: AppointmentAnalyticsProperty.SelectedAppointmentBookingModal,
    };
    return this.trackWithBookingAndSearchStates('Book Appointment Clicked', trackProps);
  }

  bookingFailed() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Failed');
  }

  bookingCancelled() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Cancelled');
  }

  appointmentBooked(appointment_id: number, inventory_id: number, appointmentTypeId: number) {
    this.trackLaunchDarklyAppointmentBooked(appointmentTypeId);

    return this.trackWithBookingAndSearchStates('Appointment Booked', {
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_id: appointment_id,
      appointment_inventory_id: inventory_id,
    });
  }

  jumpAheadClicked() {
    return this.trackWithBookingAndSearchStates('Jump Ahead Clicked', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  addToCalendarClicked() {
    return this.trackWithBookingAndSearchStates('Add To Calendar Clicked');
  }

  appointmentSurveySubmitted(surveyName: string) {
    return this.trackWithBookingAndSearchStates('Survey Submitted', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: surveyName,
    });
  }

  appointmentSurveyQuestionAnswered(surveyName: string, surveyQuestion: SurveyQuestion) {
    return this.trackWithBookingAndSearchStates('Survey Question Answered', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: surveyName,
      survey_question: surveyQuestion.questionText,
      survey_answer: surveyQuestion.patientAnswers.map(answer => answer.answerText).join(', '),
    });
  }

  reasonForCancelSubmitted() {
    return this.trackWithBookingAndSearchStates('Reason for Cancel Submitted', {
      flow: AppointmentAnalyticsProperty.RescheduleFlow,
      module: AppointmentAnalyticsProperty.ReasonForReschedulePageModule,
    });
  }

  rescheduled() {
    return this.trackWithBookingAndSearchStates('Appointment Rescheduled', {
      flow: AppointmentAnalyticsProperty.RescheduleFlow,
      module: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
    });
  }

  trackOMNowPageViewed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
    });
  }

  trackJoinVideoVisitClicked() {
    return this.trackWithDefaultProperties('Send Link Clicked', {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetVirtualCareCardSubmodule,
    });
  }

  trackMessageAProviderClicked() {
    return this.trackWithDefaultProperties('Message A Provider Clicked', {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.MessageProviderCardSubmodule,
    });
  }

  trackBookVisitClicked() {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetCareCardSubmodule,
    });
  }

  trackContinueToBookingClicked({ name: service_area_selected }: ServiceArea) {
    return this.trackWithDefaultProperties('Continue To Booking Clicked', {
      service_area_selected,
      flow: AppointmentAnalyticsProperty.OMNowNavigationFlow,
      module: AppointmentAnalyticsProperty.VirtualOnlyLandingPageModule,
      submodule: AppointmentAnalyticsProperty.GetCareCardSubmodule,
    });
  }

  trackGetCareLinkClicked({ topicName }: { topicName: string }) {
    this.trackWithBookingAndSearchStates('Get Care Link Clicked', {
      topic_name: topicName,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.DeepLinkModule,
    });
  }

  trackGetCareLDExperimentPageViewed(
    experimentName?: string,
    experimentVariation?: boolean,
    appointmentTypeId?: number,
  ) {
    // BHX Mental Health PCP Experiment Tracking (LD)
    if (appointmentTypeId.toString() === MENTAL_HEALTH_VISIT_APPT_TYPE_ID) {
      this.trackWithLaunchDarkly({ key: 'Get Care Link Used with Mental Health PCP Visit Appointment Type' });
    }
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      experiment_name: experimentName,
      experiment_variation_name: experimentVariation,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      submodule: AppointmentAnalyticsProperty.DeepLinkModule,
    });
  }

  /** BHX Program Ineligibility Events */

  // Sets the properties to pass to `.bookingStarted` to track what module/flow is being referred from
  setAppointmentBookingFromBehavioralHealthProgramIneligibility() {
    this.bookingStartedParams = {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    };
  }

  behavioralHealthProgramIneligibilityPageViewed() {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  behavioralHealthProgramIneligibilityMessageUsClicked() {
    return this.trackWithDefaultProperties('Message Us Clicked', {
      module: AppointmentAnalyticsProperty.BehavioralHealthProgramIneligiblePageModule,
      flow: AppointmentAnalyticsProperty.MemberRequestFlow,
    });
  }
  /** End BHX Program Ineligibility Events */

  /** Mindset Events */
  mindsetEducationPageViewed(source?: string) {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.MindsetEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      source,
    });
  }

  mindsetEducationAppointmentBookingStarted() {
    return this.trackWithBookingAndSearchStates('Appointment Booking Started', {
      module: AppointmentAnalyticsProperty.MindsetEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }
  /** End Mindset Events */

  /** Shift Events */
  shiftEducationPageViewed(options: string[], source?: string) {
    return this.trackWithBookingAndSearchStates(MP_EVENT_PAGE_VIEWED, {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      source: source,
      option_count: options.length,
      options: options,
    });
  }

  shiftEducationAppointmentBookingStarted(appointmentTypeName: string) {
    return this.trackWithBookingAndSearchStates('Appointment Booking Started', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      appointment_type_name: appointmentTypeName,
    });
  }

  shiftSeeAllOptionsClicked(optionCount: number) {
    return this.trackWithBookingAndSearchStates('See Options Clicked', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      option_count: optionCount,
    });
  }

  shiftCarouselInteraction(interaction: string) {
    return this.trackWithBookingAndSearchStates('Carousel Clicked', {
      module: AppointmentAnalyticsProperty.ShiftEducationPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      submodule: interaction,
    });
  }
  /** End Shift Events */

  insuranceConfirmation(
    confirmationType: 'Yes' | 'No' | 'Cancel' | 'Confirm',
    module:
      | AppointmentAnalyticsProperty.SelfPayConfirmationModule
      | AppointmentAnalyticsProperty.AppointmentConfirmationPageModule = AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
  ) {
    return this.trackWithBookingAndSearchStates('Confirm Insurance Clicked', {
      module,
      confirmation_type: confirmationType,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  trackRescheduleStarted(appointmentId: string) {
    super.trackWithDefaultProperties('Appointment Reschedule Started', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.AppointmentsPageModule,
      appointment_id: appointmentId,
    });
  }

  trackAppointmentCanceled(appointmentId: string) {
    super.trackWithDefaultProperties('Appointment Cancelled', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.AppointmentsPageModule,
      appointment_id: appointmentId,
    });
  }

  trackMarkedAsRunningLateToAppointment(appointmentId: string) {
    super.trackWithDefaultProperties('Appointment Marked As Running Late', {
      flow: AppointmentAnalyticsProperty.BookingFlow,
      module: AppointmentAnalyticsProperty.AppointmentsPageModule,
      appointment_id: appointmentId,
    });
  }

  trackWaitlistShown() {
    this.trackWithBookingAndSearchStates('Inventory Waitlist Shown', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
    });
  }

  trackWaitlistJoined(memberId: number, state: string, b2bCompanyName: string) {
    this.trackWithBookingAndSearchStates('Inventory Waitlist Joined', {
      module: AppointmentAnalyticsProperty.InventoryPageModule,
      flow: AppointmentAnalyticsProperty.BookingFlow,
      address_state: state,
      patient_id: memberId,
      b2b_company_name: b2bCompanyName,
    });
  }

  private trackLaunchDarklyAppointmentBooked(appointmentTypeId: number) {
    this.trackWithLaunchDarkly({
      key: 'Any Appointment Booked',
      data: { isModuleShown: this.searchState?.trackableProperties?.is_widget_shown },
    });
    if (appointmentTypeId === this.searchState?.trackableProperties?.remoteAppointmentTypeId) {
      this.trackWithLaunchDarkly({
        key: 'Remote Visit Appointment Booked',
        data: { isModuleShown: this.searchState?.trackableProperties?.is_widget_shown },
      });
    }
    // BHX Mental Health PCP Experiment Tracking (LD)
    if (this.isMentalHealthPcpExperiment()) {
      this.trackWithLaunchDarkly({ key: 'Patient Booked PCP Mental Health Visit' });
    }
  }

  private trackLaunchDarklyDeepLinkInventoryPageViewed() {
    // BHX Mental Health PCP Experiment Tracking (LD)
    if (this.isMentalHealthPcpExperiment()) {
      this.trackWithLaunchDarkly({ key: 'Member Appointment Inventory Page viewed' });
    }
  }

  private isMentalHealthPcpExperiment() {
    return (
      this.searchState.getBookingSource() === AppointmentBookingSource.GetCareDeepLinkFlow &&
      this.searchState.appointmentType.id.toString() === MENTAL_HEALTH_VISIT_APPT_TYPE_ID
    );
  }

  selectAppointmentClicked(
    properties: {
      serviceArea: string;
      appointmentInventoryId: string;
      selectedDate: any;
      flow: string;
      module: string;
      source: string;
      experimentName?: string;
      experimentVariantName?: string;
    },
    eventName = 'Appointment Selected',
  ) {
    return super.trackWithDefaultProperties(
      eventName,
      pickBy(
        {
          module: properties.module,
          flow: properties.flow,
          appointment_type: 'Remote Visit',
          service_area: properties.serviceArea,
          experiment_name: properties?.experimentName,
          experiment_variant_name: properties?.experimentVariantName,
          appointment_inventory_id: properties.appointmentInventoryId,
          selected_date: moment.utc(properties.selectedDate).format(ANALYTICS_TIMESTAMP_FORMAT),
          source: properties.source,
        },
        v => !isNil(v),
      ),
    );
  }
}
